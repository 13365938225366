import "../css/app.css";
import Alpine from "alpinejs"
import "lazysizes/plugins/bgset/ls.bgset"
// import "lazysizes/plugins/respimg/ls.respimg"
// import "lazysizes"
import baguetteBox from "baguettebox.js"
import scrollToElement from "scroll-to-element"
import "./components/nav"
import "./components/video"
import "./components/accordion"
import "./components/slidelabel"
import "./components/dropdown"
import "./components/mapAuerhuehner"
import "waypoints/lib/noframework.waypoints.min"
window.Alpine = Alpine
Alpine.start()

// DETECT TOUCH
document.documentElement.className +=
  "ontouchstart" in document.documentElement ? " is-touch" : " is-no-touch"

const contentwaypoint = document.getElementById("content")
if (document.body.classList.contains("has-hero")) {
  // eslint-disable-next-line no-new
  new Waypoint({
    element: contentwaypoint,
    handler: function (direction) {
      if (direction === "down") {
        document.body.classList.remove("is-hero")
      } else {
        document.body.classList.add("is-hero")
      }
    },
    offset: "64px",
  })
}

// HERO SCROLLER
const $scrollerTrigger = document.getElementById("scroller")
const $scrollerTo = document.getElementById("content")
const $offset = -112 // minus nav height or 0 if no fixed nav
if ($scrollerTrigger && $scrollerTo) {
  $scrollerTrigger.addEventListener("click", function (e) {
    e.preventDefault()
    scrollToElement($scrollerTo, {
      offset: $offset,
    })
  })
}

// Top Scroller
document.addEventListener("scroll", function () {
  if (
    document.documentElement.scrollTop > 500 ||
    document.body.scrollTop > 500
  ) {
    document.getElementById("scrolltotop").classList.add("c-scrolltotop--show")
  } else {
    document
      .getElementById("scrolltotop")
      .classList.remove("c-scrolltotop--show")
  }
})

// Lightbox Images
baguetteBox.run(".baguette", {
  noScrollbars: true,
})
