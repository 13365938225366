import Alpine from "alpinejs"
import L from "leaflet"
import "leaflet.markercluster"
import { GestureHandling } from "leaflet-gesture-handling"
import axios from "axios"
import auerhuhnverteilung from "./auerhuhnverbreitung.geo.json"

const Api = {

  getEntries() {
    // const token = ""
    const query = `query {
          entries(section: "auerhuehner") {
              ... on auerhuehner_auerhuhn_Entry {
                id
                url
                title
                intro
                female
                hideForm
                noCluster
                image {
                  url @transform(width: 600, immediately: true)
                  ... on siteImages_Asset {
                    copyright
                  }
                }
                mapLogo {
                  url @transform(width: 600, immediately: true)
                }
                mapIcon {
                  url @transform(width: 100, immediately: true)
                  width
                  height
                }
                geolocation {
                  lat
                  lng
                }
            }
          }
      }
      `
    return axios({
      method: "post",
      url: "/api",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      //   "X-Requested-With": "XMLHttpRequest"
      // },
      data: {
        query: query,
      },
    })
  },
}

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling)

Alpine.data("map", () => ({
  loading: true,
  map: null,
  markers: null,
  activeMarker: null,
  shapes: {},
  initMap() {
    this.map = L.map("map", {
      zoom: 11,
      minZoom: 9,
      tap: false,
      gestureHandling: true,
      gestureHandlingOptions: {
        text: {
          touch: "Verschieben der Karte mit zwei Fingern",
          scroll: "Verwende Strg+Scrollen zum Zoomen der Karte",
          scrollMac: "Verwende \u2318 + Scrollen zum Zoomen der Karte",
        },
        duration: 1000,
      },
      center: [47.827663, 7.943132],
      scrollWheelZoom: true,
    })

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}", {
      foo: "bar",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map)

    this.markers = L.markerClusterGroup({
      polygonOptions: {
        weight: 1.5,
        color: "#222",
        opacity: 0.5,
      },
    })
    this.addGeoJson()
    this.getEntries().then(() => {
      this.map.fitBounds(this.markers.getBounds())
      this.loading = false
    })
  },
  addMarker(element) {
    const that = this
    element.uriLogo = element.mapLogo && element.mapLogo.length != 0 && element.mapLogo[0].url;
    element.uriIcon = element.mapIcon && element.mapIcon.length != 0 && element.mapIcon[0].url;
    element.uriImage = element.image && element.image.length != 0 && element.image[0].url;
    let iconWidth = 40;
    let iconHeight = 40;
    if (element.uriIcon && (element.mapIcon[0].width !== element.mapIcon[0].height)) {
      iconWidth = 50;
      iconHeight = (element.mapIcon[0].height * 100) / element.mapIcon[0].width / 2;
    }


    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: [iconWidth, iconHeight],
        iconAnchor: [iconWidth / 2, iconHeight / 2],
      },
    })

    let myIcon = new LeafIcon({
      iconUrl: element.female ? "/assets/img/huhn.svg" : "/assets/img/hahn.svg",
    })

    if (element.uriIcon) {
      myIcon = new LeafIcon({
        iconUrl: element.uriIcon,
      })
    }

    const marker = L.marker(
      [element.geolocation.lat, element.geolocation.lng],
      { icon: myIcon }
    ).on("click", function (e) {
      that.activeMarker = element;
    })

    if (element.noCluster) {
      this.map.addLayer(marker)
    } else {
      this.markers.addLayer(marker)
    }
  },
  toggleShape(id) {
    if (this.map.hasLayer(this.shapes[id])) {
      this.map.removeLayer(this.shapes[id])
    } else {
      this.map.addLayer(this.shapes[id])
    }
  },
  isShapeActive(id) {
    if (this.map.hasLayer(this.shapes[id])) {
      return true
    } else {
      return false
    }
  },
  addGeoJson() {
    const geoJson = L.geoJSON(auerhuhnverteilung, {
      style: { weight: 1, fillOpacity: 0.1, color: "DarkGreen" },
    })
    geoJson.addTo(this.map)
  },
  async getEntries() {
    try {
      const response = await Api.getEntries()
      if (response.data.data.entries) {
        for (const element of response.data.data.entries) {
          if (element.geolocation.lat && element.geolocation.lng) {
            this.addMarker(element)
          }
        }
        this.map.addLayer(this.markers)
      }
    } catch (e) {
      console.error(e)
    }
  },
}))
