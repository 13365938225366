// Hamburger

const html = document.getElementsByTagName('html')[0]
const body = document.getElementsByTagName('body')[0]
const hamburger = document.querySelectorAll("[data-ctrl='hamburger']")[0]
let scrollPosition = 0

if (hamburger) {
  hamburger.addEventListener('click', function(e) {
    e.preventDefault()
    if (body.getAttribute('data-nav') === 'close' || body.getAttribute('data-nav') == undefined) {
      scrollPosition = window.pageYOffset
      body.setAttribute('data-nav', 'open')
      html.style.top = -scrollPosition + 'px'
    } else {
      body.setAttribute('data-nav', 'close')
      html.style.top = 0
      window.scrollTo(0, scrollPosition)
    }
  })
}

// Change after scroll

// let scrollpos = window.scrollY

// window.addEventListener("scroll", function() {
//   scrollpos = window.scrollY
//   if (scrollpos > 128) {
//     body.setAttribute("data-nav", "small")
//   } else {
//     body.setAttribute("data-nav", "large")
//   }
// })
