const slidelabels = document.querySelectorAll('[data-ctrl=\'slidelabel\']')
if (slidelabels) {
  let label
  for (var i = 0, len = slidelabels.length; i < len; i++) {
    label = slidelabels[i]
    label.addEventListener('click', function (e) {
      e.preventDefault()
      if (this.getAttribute('aria-expanded') === 'true') {
        this.setAttribute('aria-expanded', 'false')
      } else {
        this.setAttribute('aria-expanded', 'true')
      }
    })
  }
}
