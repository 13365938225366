const video = document.querySelectorAll("[data-ctrl='video']")[0]

if (video) {
  const videoUrl = video.getAttribute('data-video').replace(/youtube/i, 'youtube-nocookie')
  const videoParams = '?autoplay=1&autohide=1'
  const videoIframe = document.createElement('iframe')
  videoIframe.setAttribute('src', videoUrl + videoParams)
  video.addEventListener('click', function () {
    video.innerHTML = ''
    video.appendChild(videoIframe)
  })
}
