const accordions = document.querySelectorAll('[data-ctrl=\'accordion\']')

if (accordions) {
  let item
  for (var i = 0, len = accordions.length; i < len; i++) {
    item = accordions[i]
    const buttons = item.querySelectorAll('.c-accordion__button')
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', function () {
        if (this.getAttribute('aria-expanded') === 'true') {
          this.setAttribute('aria-expanded', 'false')
        } else {
          this.setAttribute('aria-expanded', 'true')
        }
      })
    }
  }
}
