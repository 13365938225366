const dropdowns = document.querySelectorAll('[data-ctrl=\'dropdown\']')

if (dropdowns) {
  let dropdown
  for (var i = 0, len = dropdowns.length; i < len; i++) {
    dropdown = dropdowns[i]
    const button = dropdown.querySelectorAll('.c-dropdown__button')[0]
    button.addEventListener('click', function () {
      if (dropdown.getAttribute('aria-expanded') === 'true') {
        dropdown.setAttribute('aria-expanded', 'false')
      } else {
        dropdown.setAttribute('aria-expanded', 'true')
      }
    })

    // Close Dropdowns if clicked outside
    document.addEventListener('click', function (event) {
      let isClickInside = dropdown.contains(event.target)
      if (!isClickInside) {
        dropdown.setAttribute('aria-expanded', 'false')
      }
    })
  }
}
